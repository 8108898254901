<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="fvcData"
      :fields="flugverfuegbarkeitenFields"
      responsive
      head-variant="light"
    >
      <template v-slot:cell(actions)="row">
        <div class="d-flex" style="gap: 5px">
          <b-button
            size="sm"
            :href="
              '/#/reisetermine/fvc/overview/?reise=' + row.item.reisetermin.reiseterminkuerzel.substring(0, 6)
            "
            :target="'_blank'"
          >
            FVC
          </b-button>
          <b-button size="sm" :href="'/#/reisetermine/' + row.item.reisetermin.id" :target="'_blank'">
            RT
          </b-button>
          <b-button
            size="sm"
            :href="'/#/reisen/' + row.item.reisetermin.reise.reisekuerzel + '/reisestammdaten'"
            :target="'_blank'"
          >
            R
          </b-button>
        </div>
      </template>
      <template v-slot:cell(reisetermin.reiseterminkuerzel)="data">
        <div style="width: 150px">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(status)="row">
        <h6 class="m-0">
          <span
            :class="`badge badge-${
              row.item.status === 'Verfuegbar'
                ? 'success'
                : row.item.status === 'NichtVerfuegbar'
                ? 'danger'
                : 'warning'
            }`"
          >
            {{ row.item.status === 'Verfuegbar' ? 'J' : row.item.status === 'NichtVerfuegbar' ? 'N' : 'RQ' }}
          </span>
        </h6>
      </template>
      <template v-slot:cell(reisezeitraum)="row">
        <div style="width: 150px">
          {{ formatDateWithoutHours(row.item.reisetermin.abreisedatum) }} -
          {{ formatDateWithoutHours(row.item.reisetermin.enddatum) }}
        </div>
      </template>
      <template v-slot:cell(pax)="row">
        <PaxWidget
          :paxObject="row.item.reisetermin.pax"
          :kalkPax="row.item.reisetermin.metadata.kalkPax"
        ></PaxWidget>
      </template>
      <template v-slot:cell(ziellandAbUndZielflughafen)="row">
        {{ row.item.reisetermin.metadata.ziellandZielflughaefen.map(fh => fh.flughafenIataCode).join(', ') }}
        /
        {{ row.item.reisetermin.metadata.ziellandAbflughaefen.map(fh => fh.flughafenIataCode).join(', ') }}
      </template>

      <template v-slot:cell(reisetermin.abgesagt)="row">
        <i v-if="row.item.reisetermin.abgesagt" class="fas fa-check text-success"></i>
        <i v-else class="fas fa-times text-danger"></i>
      </template>
      <template v-slot:cell(reisetermin.garantiert)="row">
        <i v-if="row.item.reisetermin.garantiert" class="fas fa-check text-success"></i>
        <i v-else class="fas fa-times text-danger"></i>
      </template>
      <template v-slot:cell(reisetermin.stopSale)="row">
        <i v-if="row.item.reisetermin.stopSale" class="fas fa-exclamation text-danger fa-lg"></i>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(aufgaben)="row">
        <b-badge :id="'tooltip-target-flugverfuegbarkeit' + row.item.id">
          {{ row.item.aufgaben?.length }}
        </b-badge>
        <b-tooltip
          :target="'tooltip-target-flugverfuegbarkeit' + row.item.id"
          triggers="hover"
          custom-class="custom-tooltip"
          v-if="row.item.aufgaben?.length > 1"
        >
          <AufgabenWidget
            :openAufgabenId="openAufgabenId"
            class="mt-3"
            :ansicht="'aufgabe-details'"
            :aufgaben="row.item.aufgaben || []"
          ></AufgabenWidget>
        </b-tooltip>
      </template>
    </b-table>
  </div>
</template>
<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import { format } from 'date-fns';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';

export default {
  components: { PaxWidget, AufgabenWidget },
  props: { fvcData: { type: Array, required: true }, openAufgabenId: { type: Number, default: null } },
  data() {
    return {
      flugverfuegbarkeitenFields: [
        {
          tdClass: 'align-middle',
          key: 'reisetermin.reiseterminkuerzel',
          label: 'Reiseterminkürzel',
        },
        { tdClass: 'align-middle', key: 'deutscherAbflughafenIataCode', label: 'Abflughafen' },
        {
          tdClass: 'align-middle',
          key: 'startdatumHotelleistung',
          label: 'Startdatum',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        { tdClass: 'align-middle', key: 'status' },
        {
          tdClass: 'align-middle',
          key: 'aufpreis',
          formatter: value => (!isNaN(parseInt(value)) ? value + '€' : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'flugstammHinflug',
          label: 'Flugstamm Hin',
          thStyle: { whiteSpace: 'nowrap' },
        },
        { tdClass: 'align-middle', key: 'zimmertyp' },
        {
          tdClass: 'align-middle',
          key: 'id',
          label: 'Flugtickets ausgestellt',
          formatter: value => this.getFlugticketsAusgestellt(value),
        },
        { tdClass: 'align-middle', key: 'pax' },
        {
          tdClass: 'align-middle',
          key: 'reisezeitraum',
        },
        {
          tdClass: 'align-middle',
          key: 'reisetermin.reise.prioritaet',
          label: 'Prio.',
          formatter: value => (value === 0 ? 'A' : value === 1 ? 'B' : value === 2 ? 'C' : 'D'),
        },
        {
          tdClass: 'align-middle',
          key: 'reisetermin.metadata.transferszenario',
          label: 'TS',
          formatter: value => value?.substring(0, 2),
        },
        {
          tdClass: 'align-middle',
          key: 'ziellandAbUndZielflughafen',
          label: 'Zielland Ziel-/Abfh.',
          thStyle: { whiteSpace: 'nowrap' },
        },
        {
          tdClass: 'align-middle',
          key: 'reisetermin.thMain',
          label: 'TH Wert',
          thStyle: { whiteSpace: 'nowrap' },
          formatter: value => (isNaN(parseInt(value)) ? '-' : value + '€'),
        },
        {
          tdClass: 'align-middle',
          key: 'abfragefehler.text',
          label: 'Abfragefehler',
        },
        {
          tdClass: 'align-middle',
          key: 'reisetermin.abgesagt',
          label: 'Abgesagt',
        },
        {
          tdClass: 'align-middle',
          key: 'reisetermin.garantiert',
          label: 'Garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'reisetermin.stopSale',
          label: 'Stop Sale',
          thStyle: { whiteSpace: 'nowrap' },
        },
        { tdClass: 'align-middle', key: 'actions', label: 'Links' },
        { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
      ],
    };
  },
  methods: {
    getFlugticketsAusgestellt(id) {
      const flugticketsAusgestellt = this.fvcData
        .filter(fvc => fvc.id === id)
        .map(fvcRow => fvcRow.reisetermin)
        .map(reisetermin => reisetermin.hotelleistungen)
        .flat()
        .map(hotelleistung => hotelleistung?.vorgang)
        .filter(v => v?.blankState === 'Buchung')
        .map(v => v?.flugticketAusgestellt);
      const countAusgestellt = flugticketsAusgestellt.reduce((acc, curr) => (acc += +curr), 0);
      const countTotal = flugticketsAusgestellt.length;
      return countAusgestellt + '/' + countTotal;
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
  },
};
</script>
