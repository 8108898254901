<template>
  <b-modal id="modalAufgabeDetails" @hide="onHide" size="xxl" @show="onShow" hide-footer>
    <template #modal-title>
      <div class="d-flex justify-content-center align-items-center">
        <span v-if="!isAufgabeUpdating">
          <i class="fa-chevron-left fas mr-2 navigationIcons" @click="goUpOneRow"></i>
          <i class="fa-chevron-right fas mr-4 navigationIcons" @click="goDownOneRow"></i>
        </span>
        <b-spinner class="mr-4" v-else></b-spinner>
        <a :href="`https://manager.skr.de/#/aufgaben/overview?aufgabenId=${aufgabeData?.id}`" target="_blank">
          <h5 class="p-0 m-0">Aufgabe {{ aufgabeData?.id }}</h5>
        </a>

        <b-button
          size="sm"
          class="ml-6"
          variant="danger"
          v-if="aufgabeData?.autor === currentUserId"
          @click="clickDeleteAufgabe"
        >
          Löschen
        </b-button>
      </div>
    </template>

    <b-overlay :show="isAufgabeUpdating" :opacity="0.5">
      <div class="aufgabenContainer">
        <div class="card card-body p-5" style="grid-column: span 2; min-width: 500px">
          <div class="d-flex flex-column" style="gap: 16px">
            <div class="d-flex align-items-center" style="gap: 8px">
              <span style="font-size: 14px; width: 30%">Bearbeiter/in</span>
              <div style="width: 73%; gap: 8px" class="d-flex">
                <UsersMultiselect
                  v-model="aufgabenDetailsData.bearbeiter"
                  :disabled="aufgabeData?.status === 'Erledigt'"
                  :class="{
                    changedField: hasZugewiesenePersonChanged,
                    'mr-2': aufgabeData?.status !== 'Erledigt',
                  }"
                  :options="zugewiesenePersonOptions"
                  class="border"
                  style="border-radius: 8px"
                  :backgroundColor="aufgabeData?.status === 'Erledigt' ? '#ededed' : ''"
                  fontSize="14px"
                  @input="onBearbeiterChange"
                ></UsersMultiselect>
                <b-button
                  v-if="aufgabeData?.status !== 'Erledigt'"
                  @click="onClickIch"
                  style="height: 38px"
                  size="sm"
                  :variant="aufgabenDetailsData.bearbeiter === currentUserId ? 'secondary' : 'primary'"
                  :disabled="aufgabenDetailsData.bearbeiter === currentUserId"
                >
                  Ich
                </b-button>
              </div>
            </div>
            <div class="d-flex align-items-center" style="gap: 8px">
              <span style="font-size: 14px; width: 30%">Team</span>
              <div style="width: 73%">
                <b-form-select
                  id="team"
                  v-model="aufgabenDetailsData.team"
                  :options="teamOptions"
                  :disabled="aufgabeData?.status === 'Erledigt'"
                  style="font-size: 14px"
                  :class="aufgabeData?.status === 'Erledigt' ? '' : 'editField'"
                  @change="onTeamChange"
                >
                  <template #first>
                    <b-form-select-option
                      v-if="aufgabenDetailsData.bearbeiter == null"
                      :value="null"
                      disabled
                      class="placeholder-multiselect"
                    >
                      -
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="d-flex align-items-center" style="gap: 8px">
              <span style="font-size: 14px; width: 30%">Kategorie</span>
              <div style="width: 73%">
                <p style="font-size: 14px; height: 38px" class="greyedOut mb-0 d-flex align-items-center">
                  {{ getKategorieName(aufgabenDetailsData.kategorie) }}
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center" style="gap: 8px">
              <span style="font-size: 14px; width: 30%">Status</span>
              <div style="width: 73%">
                <p style="font-size: 14px; height: 38px" class="greyedOut mb-0 d-flex align-items-center">
                  <span v-html="statusHtml"></span>
                </p>
              </div>
            </div>
            <div class="d-flex flex-column" style="gap: 8px">
              <span style="font-size: 14px; width: 30%">Beschreibung</span>
              <div style="width: 100%">
                <Editor
                  v-model="aufgabenDetailsData.beschreibung"
                  placeholder="-"
                  :isDisabled="aufgabeData?.status === 'Erledigt'"
                  :disableMentions="true"
                />
                <b-button
                  :disabled="!hasContentChanged"
                  :variant="hasContentChanged ? 'primary' : ''"
                  @click="saveAufgabe"
                  class="mt-4"
                  size="sm"
                  v-if="aufgabeData?.status !== 'Erledigt'"
                >
                  Speichern
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-body p-5 ml-2" style="gap: 16px; min-width: 400px">
          <div class="d-flex" style="gap: 12px">
            <div v-for="trigger in triggers" :key="trigger.trigger">
              <b-button
                :disabled="!trigger.allowed && !isErgebnisFesthalten(aufgabeData?.kategorie)"
                :variant="
                  !trigger.allowed && !isErgebnisFesthalten(aufgabeData?.kategorie)
                    ? 'secondary'
                    : triggerVariant(trigger)
                "
                @click="clickTriggerButton(trigger.trigger, aufgabeData?.kategorie)"
                size="sm"
              >
                {{
                  trigger.trigger === 'TriggerErledigt' && isErgebnisFesthalten(aufgabeData?.kategorie)
                    ? 'Ergebnis festhalten und erledigen'
                    : triggerLabel(trigger.trigger)
                }}
              </b-button>
            </div>
            <b-button
              @click="clickBeobachterButton"
              class="ml-auto d-flex justify-content-center align-items-center p-2"
              style="gap: 4px"
              :variant="isActiveUserBeobachter ? 'primary' : 'secondary'"
              :id="'aufgabe-beobachter-' + aufgabeData?.id"
            >
              <i
                class="fas fa-eye"
                style="color: black"
                :style="{
                  opactiy: isActiveUserBeobachter && 0.4,
                  color: isActiveUserBeobachter ? '#fff' : '#000',
                }"
              ></i>
              <span style="font-weight: 600; font-size: 14px">
                {{ aufgabeData?.beobachter.length }}
              </span>
            </b-button>
            <b-tooltip
              v-if="aufgabeData?.beobachter.length > 0"
              :target="'aufgabe-beobachter-' + aufgabeData?.id"
              class="p-2"
            >
              <span>Beobachter:</span>

              <li
                style="text-align: left"
                v-for="beobachter in aufgabeData?.beobachter"
                :key="'beobachter-aufgabe-' + aufgabeData?.id + '-' + beobachter"
              >
                {{ getUserName(beobachter) }}
              </li>
            </b-tooltip>
          </div>

          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%; min-width: 140px">Fälligkeitsdatum</span>
            <div style="width: 73%">
              <b-form-datepicker
                hide-header
                :disabled="aufgabeData?.status === 'Erledigt'"
                style="font-size: 14px"
                :class="{
                  changedField: hasFaelligkeitsdatumChanged,
                  greyedOut: aufgabeData?.status === 'Erledigt',
                }"
                :min="minFaelligkeitsdatum"
                id="date-picker"
                class="d-flex justify-content-center editField"
                v-model="aufgabenDetailsData.faelligkeitsdatum"
                placeholder="Kein Datum ausgewählt"
                @input="saveAufgabe"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%; min-width: 140px">Priorität</span>
            <div style="width: 73%">
              <b-form-select
                style="font-size: 14px; height: 38px"
                :class="{
                  changedField: hasPrioritaetChanged,
                  greyedOut: aufgabeData?.status === 'Erledigt',
                  editField: aufgabeData?.status !== 'Erledigt',
                }"
                :disabled="aufgabeData?.status === 'Erledigt'"
                v-model="aufgabenDetailsData.prioritaet"
                :options="prioritaetOptions"
                text-field="text"
                @change="saveAufgabe"
              ></b-form-select>
            </div>
          </div>
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%; min-width: 140px">Erstellungsdatum</span>
            <div style="width: 73%">
              <p
                style="font-size: 14px; overflow-y: auto; min-height: 38px; flex-grow: 1"
                class="greyedOut mb-0"
              >
                {{ formatDate(aufgabeData?.createdDateUtc) }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%; min-width: 140px">Startdatum</span>
            <div style="width: 73%">
              <p
                style="font-size: 14px; overflow-y: auto; min-height: 38px; flex-grow: 1"
                class="greyedOut mb-0"
              >
                {{ formatDate(aufgabeData?.statusInArbeitDatum) }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%; min-width: 140px">Beendigungsdatum</span>
            <div style="width: 73%">
              <p
                style="font-size: 14px; overflow-y: auto; min-height: 38px; flex-grow: 1"
                class="greyedOut mb-0"
              >
                {{ formatDate(aufgabeData?.statusErledigtDatum) }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%; min-width: 140px">Autor</span>
            <div style="width: 73%">
              <p
                style="font-size: 14px; overflow-y: auto; min-height: 38px; flex-grow: 1"
                class="greyedOut mb-0"
              >
                {{
                  zugewiesenePersonOptions.find(person => person.value === aufgabeData?.autor)?.text ??
                  'Automatisch erstellt'
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="card card-body p-5 mt-2" style="grid-column: span 3">
          <div class="d-flex" style="gap: 8px">
            <b-badge
              v-for="tab in tabs"
              :variant="activeTab === tab ? 'primary' : 'secondary'"
              @click="activeTab = tab"
              style="font-size: 1rem; cursor: pointer"
              :key="tab"
            >
              {{ tab }}
            </b-badge>
          </div>

          <div v-if="activeTab === 'Verlinkungen' && verlinkungExists">
            <!-- REISETERMIN VERLINKUNGEN -->
            <div class="mt-8" v-if="aufgabeData?.reiseTermine?.length > 0">
              <h6>Reisetermine</h6>
              <AufgabeAttachmentReiseterminTable
                :reiseterminData="aufgabeData?.reiseTermine"
                :aufgabenKategorie="aufgabeData?.kategorie"
                :openAufgabenId="aufgabeData.id"
              />
            </div>
            <!-- FVC VERLINKUNGEN -->
            <div class="mt-8" v-if="aufgabeData?.flugverfuegbarkeiten?.length > 0">
              <h6>FVC</h6>
              <FVCAttachmentTable
                :fvcData="aufgabeData.flugverfuegbarkeiten"
                :openAufgabenId="aufgabeData.id"
              />
            </div>
            <!-- REISE VERLINKUNGEN -->
            <div class="mt-8" v-if="aufgabeData?.reisen?.length > 0">
              <h6>Reisen</h6>
              <ReisenAttachmentTable :reisenData="aufgabeData?.reisen" :openAufgabenId="aufgabeData.id" />
            </div>
            <!-- VORGANG VERLINKUNGEN -->
            <div class="mt-8" v-if="aufgabeData?.vorgaenge?.length > 0">
              <h6>Vorgänge</h6>
              <VorgangAttachmentTable
                :vorgangData="aufgabeData.vorgaenge"
                :isFomAufgabe="isFomAufgabe"
                :openAufgabenId="aufgabeData.id"
              />
            </div>
          </div>

          <div v-if="activeTab === 'Verlauf'">
            <AufgabenVerlauf :data="verlaufData" />
          </div>
        </div>
        <div class="card card-body p-5 mt-2" style="grid-column: span 2">
          <!-- Kommentare -->
          <div>
            <h5 class="mb-4">Kommentare</h5>
            <div v-if="isCommentsLoading" class="position-absolute top-0" style="right: 32px">
              <b-spinner label="Spinning"></b-spinner>
            </div>
            <div class="d-flex flex-column">
              <div
                class="mb-4"
                style="max-height: 500px; overflow-y: scroll"
                v-if="isCommented"
                id="commentsList"
              >
                <Comment
                  v-for="comment in getCommentsFromSelectedAufgabe"
                  :key="comment.id"
                  :comment="comment"
                  class="mb-2 comment-box"
                  @comment-edit="editComment"
                  @comment-delete="deleteComment"
                  @comment-reply="clickOnReplyButton"
                  @reactionMade="reactionMade"
                />
              </div>
              <div
                v-if="getCommentsFromSelectedAufgabe && getCommentsFromSelectedAufgabe?.length === 0"
                class="mb-4"
              >
                Keine Kommentare.
              </div>
              <div class="mt-2">
                <CommentInputField
                  @save-comment="saveComment"
                  @save-edit-comment="saveEditComment"
                  @cancel-edit="cancelEditComment"
                  @cancel-reply="cancelReply"
                  @save-reply-comment="saveReplyComment"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card card-body p-5 ml-2 mt-2" v-if="aufgabeData?.ergebnis" style="gap: 16px">
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%">Ergebnis</span>
            <div style="width: 73%">
              <p style="font-size: 14px; height: 38px" class="greyedOut mb-0 d-flex align-items-center">
                {{ getErgebnisText }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center" style="gap: 8px">
            <span style="font-size: 14px; width: 30%">Beschreibung</span>
            <div style="width: 73%">
              <p style="font-size: 14px; height: 38px" class="greyedOut mb-0 d-flex align-items-center">
                {{ aufgabeData?.ergebnisBeschreibung ?? '-' }}
              </p>
            </div>
          </div>
        </div>
        <AufgabeErgebnisFesthaltenModal
          :aufgabeId="aufgabeData?.id ?? 0"
          :aufgabenKategorie="aufgabeData?.kategorie ?? ''"
          @ergebnisGespeichert="onErgebnisGespeichert"
        />
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';
import UsersMultiselect from '@/components/common/users-multiselect.vue';

import { format } from 'date-fns';
import Comment from '@/components/aufgaben/comment.vue';
import CommentInputField from '@/components/aufgaben/comment-input-field.vue';
import AufgabeErgebnisFesthaltenModal from '@/components/aufgaben/aufgabe-ergebnis-festhalten-modal.vue';
import FVCAttachmentTable from '@/components/aufgaben/aufgabe-attachment-fvc-table.vue';
import ReisenAttachmentTable from '@/components/aufgaben/aufgabe-attachment-reise-table.vue';
import VorgangAttachmentTable from '@/components/aufgaben/aufgabe-attachment-vorgang-table.vue';
import AufgabenVerlauf from '@/components/aufgaben/aufgaben-verlauf.vue';
import {
  SET_AUFGABE_DETAIL_DATA,
  EDIT_AUFGABE,
  SAVE_COMMENT,
  DELETE_COMMENT,
  SET_EDIT_COMMENT_ID,
  SAVE_EDIT_COMMENT,
  SET_COMMENT_TEXT,
  SET_REPLY_COMMENT_ID,
  SAVE_REPLY_COMMENT,
  SET_MODE,
  LOAD_TRIGGERS,
  EDIT_STATUS,
  ADD_BEOBACHTER,
  DELETE_BEOBACHTER,
} from '@/core/aufgaben/stores/aufgaben.module';
import { mapState, mapGetters } from 'vuex';
import { isEqual } from 'date-fns';
import configPriority from '@/core/produkte/reisen/priority.config.json';
import {
  changePeopleToCorrectShape,
  triggerLabel,
  triggerVariant,
  permittedDepartments,
  ergebnisOptions,
  statusFormatterHtml,
} from '@/core/common/helpers/utils.js';
import AufgabeAttachmentReiseterminTable from '@/components/aufgaben/aufgabe-attachment-reisetermin-table.vue';
import Editor from '@/components/common/editor.vue';

export default {
  name: 'AufgabeDetailsModal',
  props: { showAttachments: { type: Boolean, default: true } },
  components: {
    Comment,
    CommentInputField,
    Editor,
    UsersMultiselect,
    AufgabeAttachmentReiseterminTable,
    AufgabeErgebnisFesthaltenModal,
    FVCAttachmentTable,
    ReisenAttachmentTable,
    VorgangAttachmentTable,
    AufgabenVerlauf,
  },
  data() {
    return {
      ergebnisOptions,
      triggers: [],
      prioritaetOptions: [
        { value: 0, text: 'Niedrig' },
        { value: 1, text: 'Mittel' },
        { value: 2, text: 'Hoch' },
      ],
      aufgabenDetailsData: {
        beschreibung: null,
        faelligkeitsdatum: null,
        bearbeiter: null,
        prioritaet: null,
        kategorie: null,
        team: null,
      },
      isTooltipVorgangLoading: false,
      vorgangTooltipData: [],
      userIdWebstada: 'a7dfc360-dd20-4f2d-a195-a47b4bcfa4b0',
      showAllFvcAttachments: false,
      isBeschreibungCollapsed: false,
      activeTab: 'Verlinkungen',
      verlaufData: null,
      tabs: ['Verlinkungen', 'Verlauf'],
      statusFormatterHtml,
    };
  },
  watch: {
    aufgabeData(newAufgabeData) {
      if (newAufgabeData != null) {
        this.loadTriggers();
        this.loadVerlauf(this.aufgabeData?.id);
        this.initializeData();
      }
    },
  },
  computed: {
    ...mapGetters([
      'getCommentsFromSelectedAufgabe',
      'parsedRoles',
      'currentUserId',
      'getKategorienWithErgebnisFesthalten',
      'getKategorienLabel',
      'getKategorienReleaseprozess',
    ]),
    ...mapState({
      aufgabeData: state => state.aufgaben.aufgabeDetail,
      isAufgabeUpdating: state => state.aufgaben.isAufgabeUpdating,
      users: state => state.users['users'],
      isCommentsLoading: state => state.aufgaben.isCommentsBusy,
    }),
    statusHtml() {
      if (this.aufgabeData) return this.statusFormatterHtml[this.aufgabeData.status];
      return '-';
    },
    bearbeiterValue() {
      return this.aufgabenDetailsData.bearbeiter;
    },
    isReleaseprozessAufgabe() {
      return this.getKategorienReleaseprozess
        .map(kategorie => kategorie.aufgabenKategorie)
        .includes(this.aufgabeData?.kategorie);
    },
    isZvcAufgabe() {
      return (
        this.aufgabeData?.kategorie === 'ZimmerkontingentePruefenMaxPax' ||
        this.aufgabeData?.kategorie === 'ZimmerkontingentePruefenErwartetePax'
      );
    },
    isFomAufgabe() {
      return (
        this.aufgabeData?.kategorie === 'FlugticketsVorzeitigAusstellen' ||
        this.aufgabeData?.kategorie === 'VorabpruefungTicketBeiGruppe' ||
        this.aufgabeData?.kategorie === 'TicketfreigabeYield' ||
        this.aufgabeData?.kategorie === 'TicketRefund'
      );
    },
    showAttachmentByCategory() {
      const releaseprozessKategorien = this.getKategorienReleaseprozess.map(k => k.aufgabenKategorie);
      return [
        ...releaseprozessKategorien,
        'MtzErreicht',
        'MtzUnterschritten',
        'ZimmerkontingentePruefenErwartetePax',
        'ZimmerkontingentePruefenMinPax',
      ].includes(this.aufgabeData?.kategorie);
    },
    isActiveUserBeobachter() {
      return this.aufgabeData?.beobachter.includes(this.currentUserId);
    },
    isCommented() {
      return this.getCommentsFromSelectedAufgabe?.length > 0;
    },
    isAdmin() {
      return this.parsedRoles.includes('Admin');
    },
    peopleWithRestrictedPermission() {
      return this.users?.filter(
        user => permittedDepartments.includes(user.department) || user.id === this.userIdWebstada
      );
    },
    teamOptions() {
      if (this.isAdmin) {
        return this.changeTeamsToCorrectShape(this.users);
      } else {
        return this.changeTeamsToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
    zugewiesenePersonOptions() {
      if (this.isAdmin) {
        return changePeopleToCorrectShape(this.users);
      } else {
        return changePeopleToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
    hasFaelligkeitsdatumChanged() {
      return !isEqual(
        new Date(this.aufgabeData?.faelligkeitsdatum?.substring(0, 10)),
        new Date(this.aufgabenDetailsData.faelligkeitsdatum?.substring(0, 10))
      );
    },
    hasBeschreibungChanged() {
      const nullFormattedBeschreibung = this.aufgabenDetailsData.beschreibung ?? '';
      const nullFormattedBeschreibung2 = this.aufgabeData?.beschreibung ?? '';
      return nullFormattedBeschreibung2 != nullFormattedBeschreibung;
    },
    hasZugewiesenePersonChanged() {
      return this.aufgabeData?.bearbeiter !== this.aufgabenDetailsData.bearbeiter;
    },
    hasPrioritaetChanged() {
      return this.aufgabeData?.prioritaet !== this.aufgabenDetailsData.prioritaet;
    },
    hasContentChanged() {
      return (
        this.hasPrioritaetChanged ||
        this.hasBeschreibungChanged ||
        this.hasFaelligkeitsdatumChanged ||
        this.hasZugewiesenePersonChanged
      );
    },
    hasTeamChanged() {
      return this.aufgabeData?.team !== this.aufgabenDetailsData.team;
    },
    minFaelligkeitsdatum() {
      return new Date();
    },
    verlinkungExists() {
      if (this.aufgabeData) {
        return (
          this.aufgabeData.reiseTermine?.length > 0 ||
          this.aufgabeData.reisen?.length > 0 ||
          this.aufgabeData.vorgaenge?.length > 0 ||
          this.aufgabeData.flugverfuegbarkeiten?.length > 0
        );
      } else return false;
    },
    showFlugverfuegbarkeiten() {
      if (this.showAllFvcAttachments) {
        return this.aufgabeData?.flugverfuegbarkeiten;
      } else {
        return this.aufgabeData?.flugverfuegbarkeiten.slice(0, 5);
      }
    },
    getErgebnisText() {
      if (this.aufgabeData && this.aufgabeData.ergebnis) {
        return this.ergebnisOptions.find(item => item.value === this.aufgabeData.ergebnis)?.text;
      }
      return '';
    },
  },

  methods: {
    changeTeamsToCorrectShape(data) {
      const duplicateDepartments = data
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ department }) => department);
      return [...new Set(duplicateDepartments)]
        .filter(dep => dep !== null)
        .map(department => ({ value: department, text: department }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    onErgebnisGespeichert() {
      this.$store
        .dispatch(EDIT_STATUS, { aufgabeId: this.aufgabeData.id, trigger: 'TriggerErledigt' })
        .then(response => {
          this.toast('Status wurde bearbeitet.');
          this.$emit('aufgabeBearbeitet', response);
          this.loadTriggers();
        });
    },
    isErgebnisFesthalten(kategorie) {
      return this.getKategorienWithErgebnisFesthalten.includes(kategorie);
    },
    triggerVariant,
    triggerLabel,
    clickBeobachterButton() {
      const aufgabeId = this.aufgabeData?.id;
      const userId = this.currentUserId;
      if (this.isActiveUserBeobachter) {
        this.$store.dispatch(DELETE_BEOBACHTER, { id: aufgabeId, userId }).then(updatedAufgabe => {
          this.$emit('updateOverviewData', updatedAufgabe);
          this.toast('Bearbeitet');
        });
      } else {
        this.$store.dispatch(ADD_BEOBACHTER, { id: aufgabeId, userId }).then(updatedAufgabe => {
          this.$emit('updateOverviewData', updatedAufgabe);
          this.toast('Bearbeitet');
        });
      }
    },
    async clickDeleteAufgabe() {
      const confirmed = await this.$bvModal.msgBoxConfirm('Willst du die Aufgabe wirklich löschen?', {
        centered: true,
        okTitle: 'Löschen',
        cancelTitle: 'Abbrechen',
        okVariant: 'danger',
      });
      if (confirmed && this.aufgabeData.id) {
        apiService.delete('/aufgaben', { id: this.aufgabeData.id }).then(() => {
          this.$router.go('/aufgaben/overview');
        });
      }
    },
    getKategorieName(kategorieEnum) {
      return this.getKategorienLabel(kategorieEnum);
    },
    reactionMade(updatedAufgabe) {
      this.$emit('updateOverviewData', updatedAufgabe);
    },

    loadTriggers() {
      this.$store
        .dispatch(LOAD_TRIGGERS, { aufgabeId: this.aufgabeData?.id })
        .then(res => (this.triggers = res));
    },
    saveReplyComment() {
      this.$store.dispatch(SAVE_REPLY_COMMENT).then(res => {
        this.$emit('updateOverviewData', res);
        this.toast('Antwort auf Kommentar wurde erstellt.');
      });
    },
    clickOnReplyButton(commentId) {
      this.$store.commit(SET_MODE, 'reply');
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_EDIT_COMMENT_ID, null);
      this.$store.commit(SET_REPLY_COMMENT_ID, commentId);
    },
    cancelReply() {
      this.$store.commit(SET_MODE, '');
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_REPLY_COMMENT_ID, null);
    },
    cancelEditComment() {
      this.$store.commit(SET_MODE, '');
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_EDIT_COMMENT_ID, null);
    },
    saveEditComment(commentText) {
      this.$store.dispatch(SAVE_EDIT_COMMENT, { commentText }).then(res => {
        this.$emit('updateOverviewData', res);
        this.toast('Kommentar wurde geändert.');
      });
    },
    async deleteComment(commentId) {
      const confirmed = await this.$bvModal.msgBoxConfirm('Willst du den Kommentar wirklich löschen?', {
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
      });
      if (confirmed) {
        this.$store.dispatch(DELETE_COMMENT, { commentId }).then(res => {
          this.$emit('updateOverviewData', res);
          this.toast('Kommentar wurde gelöscht.');
        });
        this.$store.commit(SET_COMMENT_TEXT, '');
        this.$store.commit(SET_MODE, '');
        this.$store.commit(SET_EDIT_COMMENT_ID, null);
        this.$store.commit(SET_REPLY_COMMENT_ID, null);
      }
    },
    editComment(commentId, commentText) {
      this.$store.commit(SET_COMMENT_TEXT, commentText);
      this.$store.commit(SET_MODE, 'edit');
      this.$store.commit(SET_REPLY_COMMENT_ID, null);

      this.$store.commit(SET_EDIT_COMMENT_ID, commentId);
    },
    saveComment(commentText) {
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.dispatch(SAVE_COMMENT, { text: commentText }).then(res => {
        this.toast('Kommentar wurde gespeichert.');
        this.$emit('updateOverviewData', res);
      });
    },
    onHide() {
      this.$store.commit(SET_AUFGABE_DETAIL_DATA, null);
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_MODE, '');
      this.removeKeyEventListener();
    },
    onShow() {
      this.initializeData();
      this.loadVerlauf(this.aufgabeData?.id);
      this.addKeyEventListener();
    },
    initializeData() {
      this.aufgabenDetailsData.beschreibung = this.aufgabeData?.beschreibung;
      this.aufgabenDetailsData.faelligkeitsdatum = this.aufgabeData?.faelligkeitsdatum;
      this.aufgabenDetailsData.bearbeiter = this.aufgabeData?.bearbeiter;
      this.aufgabenDetailsData.prioritaet = this.aufgabeData?.prioritaet;
      this.aufgabenDetailsData.reisetermine = this.aufgabeData?.reiseTermine;
      this.aufgabenDetailsData.reisen = this.aufgabeData?.reisen;
      this.aufgabenDetailsData.flugverfuegbarkeiten = this.aufgabeData?.flugverfuegbarkeiten;
      this.aufgabenDetailsData.vorgaenge = this.aufgabeData?.vorgaenge;
      this.aufgabenDetailsData.kategorie = this.aufgabeData?.kategorie;
      this.aufgabenDetailsData.team = this.aufgabeData?.team;
      this.isBeschreibungCollapsed = this.aufgabeData?.beschreibung?.length > 0;
    },
    async saveAndClose() {
      const changedFields = {
        beschreibung: this.hasBeschreibungChanged
          ? this.aufgabenDetailsData.beschreibung
          : this.aufgabeData.beschreibung,
        faelligkeitsdatum: this.hasFaelligkeitsdatumChanged
          ? this.aufgabenDetailsData.faelligkeitsdatum
          : this.aufgabeData.faelligkeitsdatum,
        bearbeiter: this.hasZugewiesenePersonChanged
          ? this.aufgabenDetailsData.bearbeiter
          : this.aufgabeData.bearbeiter,
        prioritaet: this.hasPrioritaetChanged
          ? this.aufgabenDetailsData.prioritaet
          : this.aufgabeData.prioritaet,
        id: this.aufgabeData.id,
      };
      // user darf alles ändern
      this.$store.dispatch(EDIT_AUFGABE, changedFields).then(updatedAufgabe => {
        this.toast('Aufgabe wurde bearbeitet.');
        this.$emit('aufgabeBearbeitet', updatedAufgabe);
        this.$bvModal.hide('modalAufgabeDetails');
      });
    },
    async saveAufgabe() {
      const changedFields = {
        beschreibung: this.hasBeschreibungChanged
          ? this.aufgabenDetailsData.beschreibung
          : this.aufgabeData.beschreibung,
        faelligkeitsdatum: this.hasFaelligkeitsdatumChanged
          ? this.aufgabenDetailsData.faelligkeitsdatum
          : this.aufgabeData.faelligkeitsdatum,
        bearbeiter: this.hasZugewiesenePersonChanged
          ? this.aufgabenDetailsData.bearbeiter
          : this.aufgabeData.bearbeiter,
        prioritaet: this.hasPrioritaetChanged
          ? this.aufgabenDetailsData.prioritaet
          : this.aufgabeData.prioritaet,
        team: this.hasTeamChanged ? this.aufgabenDetailsData.team : this.aufgabeData.team,
        id: this.aufgabeData.id,
      };
      // zugewiesener user darf alles ändern
      this.$store.dispatch(EDIT_AUFGABE, changedFields).then(updatedAufgabe => {
        this.$emit('aufgabeBearbeitet', updatedAufgabe);
        this.toast('Aufgabe wurde bearbeitet.');
      });
    },
    goUpOneRow() {
      const isEditorActive = Array.from(document.getElementsByClassName('tiptap')).some(
        item => document.activeElement === item
      );

      if (!isEditorActive && !this.isAufgabeUpdating) {
        this.$emit('rowSelectionChanged', 'up');
      }
    },
    goDownOneRow() {
      const isEditorActive = Array.from(document.getElementsByClassName('tiptap')).some(
        item => document.activeElement === item
      );
      if (!isEditorActive && !this.isAufgabeUpdating) {
        this.$emit('rowSelectionChanged', 'down');
      }
    },
    handleKeyPress($event) {
      const isBeschreibungActive = document.activeElement === document.getElementById('input-beschreibung');
      if (!isBeschreibungActive) {
        if ($event.key === 'ArrowLeft') {
          this.goUpOneRow();
        } else if ($event.key === 'ArrowRight') {
          this.goDownOneRow();
        }
      }
    },
    addKeyEventListener() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    removeKeyEventListener() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    getZiellandAbflugZielflughafen(ziellandAbflughaefen, ziellandZielflughaefen) {
      const allAbflughaefen =
        ziellandAbflughaefen?.length === 0
          ? '-'
          : ziellandAbflughaefen.map(flughafen => flughafen.flughafenIataCode).join(', ');
      const allZielflughaefen =
        ziellandZielflughaefen?.length === 0
          ? '-'
          : ziellandZielflughaefen?.map(flughafen => flughafen.flughafenIataCode).join(', ');
      return `Zielland Ab-/Zielflughafen: ${allAbflughaefen} / ${allZielflughaefen}`;
    },
    formatDate(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy HH:mm');
      } else return '';
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
    async onShowTooltipVorgang(vorgangId) {
      if (this.vorgangTooltipData.filter(vorgang => vorgang.id === vorgangId).length === 0) {
        this.isTooltipVorgangLoading = true;
        await odataService
          .getVorgang({
            filter: { id: vorgangId },
            top: 1,
            expand: { kunde: { expand: { master: {} } } },
          })
          .then(response => {
            this.isTooltipVorgangLoading = false;
            this.vorgangTooltipData = [...this.vorgangTooltipData, response.data[0]];
          })
          .catch(err => {
            this.isTooltipVorgangLoading = false;
            console.error(err);
          });
      }
    },
    clickTriggerButton(trigger, kategorie) {
      if (trigger === 'TriggerErledigt' && this.isErgebnisFesthalten(kategorie)) {
        this.$bvModal.show('aufgabeErgebnisFesthaltenModal');
      } else {
        this.$store.dispatch(EDIT_STATUS, { aufgabeId: this.aufgabeData.id, trigger }).then(response => {
          this.toast('Status wurde bearbeitet.');
          this.$emit('aufgabeBearbeitet', response);
          this.loadTriggers();
        });
      }
    },
    getUserName(userId) {
      const user = this.users.find(user => user.id === userId);
      if (!user) return '';
      return user.givenName + ' ' + user.surname;
    },
    onBearbeiterChange() {
      this.aufgabenDetailsData.team = null;
      this.saveAufgabe();
    },
    onClickIch() {
      this.aufgabenDetailsData.bearbeiter = this.currentUserId;
      this.onBearbeiterChange();
    },
    onTeamChange() {
      this.aufgabenDetailsData.bearbeiter = null;
      this.saveAufgabe();
    },
    loadVerlauf(aufgabenId) {
      if (aufgabenId) {
        apiService.get('/history/aufgaben/' + aufgabenId).then(response => {
          if (response.status === 200) {
            this.verlaufData = response.data.result;
          }
        });
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.bigger-font {
  font-size: 14px;
}
p {
  font-size: 14px;
  border-radius: 0.42rem;
  padding: 0.65rem 1rem;
  border: 1px solid transparent;
  max-height: 200px;
}
.editField {
  border: 1px solid rgba(128, 128, 128, 0.2);
}
.editField:focus {
  outline: none !important;
  outline-width: 0 !important;
}

.editField:hover {
  border: 1px solid rgba(128, 128, 128, 0.544);
  border-radius: 8px;
}
.changedField {
  border: 1px solid rgba(59, 85, 180, 0.544) !important;
  outline: none;
  border-radius: 8px;
}
.custom-select:active {
  outline: none !important;
  outline-width: 0 !important;
}

.custom-select {
  --webkit-box-shadow: none;
  box-shadow: none;
}
.editField > btn {
  padding: 0px;
}

.greyedOut {
  background-color: rgba(114, 114, 114, 0.05) !important;
  border: none;
}

.custom-select:disabled {
  background-color: rgba(114, 114, 114, 0.05) !important;
}

select:disabled {
  opacity: 1;
  color: #464e5f;
}

label#date-picker__value_ {
  padding-top: 10px;
}

.navigationIcons:hover {
  color: #3699ff;
}

.navigationIcons {
  color: black;
  cursor: pointer;
}
:deep(.tooltip) {
  opacity: 1 !important;
}
:deep(.custom-tooltip) > .tooltip-inner {
  /* Removes the default max-width */
  max-width: none;

  /* Apply whatever other styles you want */
}
:deep(.modal) .modal-xxl {
  max-width: min(1800px, 92vw);
  width: min(1800px, 92vw);
}

.aufgabenContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

:deep(.modal-xxl .modal-content) {
  background-color: #eef0f8;
  padding: 0;
  height: 95vh;
  max-height: 95vh;
  margin: 0;
}

:deep(.modal-xxl .modal-header) {
  background-color: white;
}

:deep(.modal-xxl .modal-body) {
  padding: 1rem;
  overflow: auto;
}
:deep(.ck.ck-dropdown .ck-dropdown__arrow) {
  z-index: none;
}
</style>
